import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

type SocialsContainerProps = {
    expand: boolean;
};

export const SocialsContainer = styled.div<SocialsContainerProps>`
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    right: 10px;
    z-index: 10;
    bottom: 265px;

    ${breakpoints.abovePhone} {
        bottom: 320px;
    }

    ${breakpoints.aboveTablet} {
        bottom: calc(10% + 250px);
    }

    a {
        cursor: pointer;
        margin-bottom: 15px;
        opacity: ${({expand}) => (expand ? 1 : 0)};
        pointer-events: ${({expand}) => (expand ? 'all' : 'none')};
        color: #ee4492;
        transition: opacity 0.2s 0.15s ease-in-out, color 0.3s ease-in-out;

        ${breakpoints.aboveTablet} {
            opacity: 1;
            pointer-events: all;
            color: rgba(238, 68, 146, 0.5);

            &:hover {
                color: #ee4492;
            }
        }
    }

    button {
        background: none;
        padding: 0;
        margin: 0;
        border: 0;
        margin-bottom: 20px;
        transform: ${({expand}) => (expand ? 'rotate(180deg)' : 'none')};
        transition: transform 0.2s ease-in-out;

        ${breakpoints.aboveTablet} {
            display: none;
        }
    }
`;
