import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    position: relative;

    ${breakpoints.tablet} {
        overflow: hidden;
    }
`;

export const Content = styled.div`
    #staking-modal.open ~ & {
        filter: brightness(0.1);
        pointer-events: none;
    }
`;
