import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

export const Wrapper = styled.header`
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(
            180deg,
            #000000 4px,
            rgba(0, 0, 0, 0.744048) 32px,
            rgba(0, 0, 0, 0) 80px
        )
        no-repeat;

    ${breakpoints.tablet} {
        &.open {
            overflow-y: auto;
            height: 100vh;
        }
    }
`;

export const Container = styled.div`
    max-width: 1224px;
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    padding: 16px 16px;

    ${breakpoints.aboveTablet} {
        box-sizing: content-box;
        align-items: center;
        justify-content: space-between;
        padding: 16px 50px;
        max-width: 100%;
    }

    ${breakpoints.aboveDesktop} {
        padding: 16px 100px;
    }

    ${breakpoints.tablet} {
        flex-direction: column;
        pointer-events: none;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(224deg, #00031b 16%, #4e0138 86%);
            background-repeat: no-repeat;
            transform: translateY(-100%);
            transition: transform ease-in-out 0.3s;
        }

        &.open {
            pointer-events: all;
            &::before {
                transition-delay: 0.3s;
                transform: translateY(0);
            }
        }
    }
`;

export const Logo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: all;

    img {
        height: 48px;
        max-height: 100%;
        max-width: 100%;
    }

    ${breakpoints.aboveTablet} {
        padding-right: 20px;

        img {
            height: 64px;
        }
    }
`;

export const HamburgerButton = styled.button`
    background: none;
    appearance: none;
    padding: 0;
    outline: 0;
    border: 0;
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
    margin-left: 20px;

    ${breakpoints.aboveTablet} {
        display: none;
    }
`;

export const FoldContainer = styled.nav`
    display: flex;

    ${breakpoints.tablet} {
        flex-grow: 1;
        flex-direction: column;
        width: 100%;
        padding: 20px 4px 40px;
        transform: translateX(-100%);
        transition: transform ease-in-out 0.2s;

        &.open {
            transition-delay: 0.3s;
            transform: translateX(0);
        }
    }

    ${breakpoints.aboveTablet} {
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        gap: 32px;
    }
`;

export const StyledLink = styled.a`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    font-weight: 300;
    font-size: 22px;
    white-space: nowrap;
    ${breakpoints.aboveTablet} {
        font-size: 16px;
        font-weight: 400;
    }
`;

export const Submenu = styled.ul`
    padding: 0;
    margin: 0;
    flex-direction: column;
    position: relative;
    top: 14px;
    left: 30px;
    a {
        display: block;
    }

    ${breakpoints.tablet} {
        display: flex;

        a {
            margin: 20px 0;
        }
        a {
            margin: 0;
        }
    }

    ${breakpoints.aboveTablet} {
        position: absolute;
        top: 20px;
        padding-top: 15px;
        background-clip: content-box;
        display: none;
        left: 50%;
        transform: translateX(-50%);

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 20px;
            background-color: black;
            z-index: -1;
        }

        a {
            margin: 10px 8px 0 8px;
            padding: 15px;
            border-radius: 16px;

            &:first-child {
                margin-bottom: 0;
            }

            &:hover {
                background-color: rgba(216, 69, 148, 0.5);
            }
        }
    }
`;

export const Dropdown = styled.ul`
    padding: 0;
    position: relative;

    > ${StyledLink} {
        pointer-events: none;
    }

    ${breakpoints.aboveTablet} {
        &:hover {
            ${Submenu} {
                display: flex;
            }
        }
    }
`;
