import styled from 'styled-components';

type ContainerProps = {};
export const Container = styled.a<ContainerProps>`
    transition: color ease-in-out 0.15s;
    color: #d84594;
    text-decoration: underline;

    &:hover {
        color: #852b65;
    }
`;
