import React, {createContext, ReactNode, useContext, useState} from 'react';

import {PageQueryPageDataAttributesContentComponentAtomsStakingPopup} from '~interfaces/graphql/PageQuery';

const StakingModalContentContext = createContext<{
    content:
        | PageQueryPageDataAttributesContentComponentAtomsStakingPopup
        | undefined;
    setContent: (
        content: PageQueryPageDataAttributesContentComponentAtomsStakingPopup,
    ) => void;
}>({
    content: undefined,
    setContent: () => {},
});

export const StakingModalContentProvider = ({children}: {children: ReactNode}) => {
    const [content, setContent] = useState<
        PageQueryPageDataAttributesContentComponentAtomsStakingPopup | undefined
    >(undefined);

    return (
        <StakingModalContentContext.Provider value={{content, setContent}}>
            {children}
        </StakingModalContentContext.Provider>
    );
};

export const useModalContent = () => useContext(StakingModalContentContext);
