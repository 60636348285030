/* eslint-disable camelcase */
import Particles from 'react-tsparticles';

import {
    LeftBowIcon,
    LeftBowIconTop,
    RightBowIcon,
} from '~organisms/Layout/Background/Homepage/styles';

import {Background, Stars, Top} from './styles';

export const StartBackground = () => {
    return (
        <Background>
            <Top />
            <Stars>
                <Particles
                    options={{
                        fullScreen: false,
                        particles: {
                            number: {
                                value: 5000,
                                density: {
                                    enable: true,
                                    value_area: 789.1476416322727,
                                },
                            },
                            color: {
                                value: [
                                    '#ffffff',
                                    '#625376',
                                    '#195A79',
                                    '#0D103C',
                                    '#226672',
                                ],
                            },
                            shape: {
                                type: 'circle',
                                stroke: {
                                    width: 0,
                                    color: '#000000',
                                },
                                polygon: {
                                    nb_sides: 5,
                                },
                            },
                            opacity: {
                                value: 0.2,
                                random: true,
                                anim: {
                                    enable: true,
                                    speed: 0.2,
                                    opacity_min: 0,
                                    sync: false,
                                },
                            },
                            size: {
                                value: 2,
                                random: true,
                                anim: {
                                    enable: true,
                                    speed: 2,
                                    size_min: 0,
                                    sync: false,
                                },
                            },
                            line_linked: {
                                enable: false,
                                distance: 150,
                                color: '#ffffff',
                                opacity: 0.4,
                                width: 1,
                            },
                            move: {
                                enable: true,
                                speed: 0.2,
                                direction: 'none',
                                random: true,
                                straight: false,
                                out_mode: 'out',
                                bounce: false,
                                attract: {
                                    enable: false,
                                    rotateX: 600,
                                    rotateY: 1200,
                                },
                            },
                        },
                        interactivity: {
                            detect_on: 'canvas',
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: 'bubble',
                                },
                                onclick: {
                                    enable: true,
                                    mode: 'push',
                                },
                                resize: true,
                            },
                            modes: {
                                grab: {
                                    distance: 400,
                                    line_linked: {
                                        opacity: 1,
                                    },
                                },
                                bubble: {
                                    distance: 83.91608391608392,
                                    size: 1,
                                    duration: 3,
                                    opacity: 1,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                                push: {
                                    particles_nb: 4,
                                },
                                remove: {
                                    particles_nb: 2,
                                },
                            },
                        },
                        retina_detect: true,
                    }}
                />
            </Stars>
            <LeftBowIcon />
            <LeftBowIconTop />
            <RightBowIcon />
        </Background>
    );
};
