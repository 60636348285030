import React from 'react';

import Dropdown from '~components/molecules/Menu/Dropdown';
import {GlobalComponentsQueryMenuDataAttributesLinks} from '~interfaces/graphql/GlobalComponentsQuery';
import {LinkQueryChildrenLinks} from '~interfaces/graphql/LinkQuery';

import PageLink from '../PageLink';

type MemoizedLinkProps = {
    link: GlobalComponentsQueryMenuDataAttributesLinks;
};

const LinkMemo = React.memo(PageLink);
const DropdownMemo = React.memo(Dropdown);

const MemoizedLink = ({link}: MemoizedLinkProps) => {
    return link.childrenLinks && link.childrenLinks.length > 0 ? (
        <DropdownMemo
            key={link.text}
            text={link.text}
            children={link.childrenLinks as LinkQueryChildrenLinks[]}
        />
    ) : (
        <LinkMemo link={link} key={link.text} />
    );
};

export default MemoizedLink;
