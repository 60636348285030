import React, {useState} from 'react';
import classNames from 'classnames';
import {GlobalComponentsQueryMenuDataAttributes} from 'interfaces/graphql/GlobalComponentsQuery';

import {getImageData} from '~utils/imageData';
import {Routes} from '~interfaces/Routes';
import CustomLink from '~components/atoms/CustomLink';
import HamburgerIcon from '~assets/icons/hamburger.svg';
import CloseIcon from '~assets/icons/close.svg';

import LinksList from '../LinksList';

import {Container, FoldContainer, HamburgerButton, Logo, Wrapper} from './styles';

type MenuProps = GlobalComponentsQueryMenuDataAttributes;

const Menu: React.FC<MenuProps> = ({logo, links}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper className={classNames(isOpen && 'open')}>
            <Container
                className={classNames(isOpen && 'open')}
                onClick={(ev) =>
                    ((ev.target as HTMLElement)?.tagName === 'A' ||
                        (
                            (ev.target as HTMLElement | null)
                                ?.parentNode as HTMLElement | null
                        )?.tagName === 'A') &&
                    setIsOpen(false)
                }
            >
                <Logo>
                    <CustomLink href={Routes.Homepage}>
                        {logo && <img {...getImageData(logo)} />}
                    </CustomLink>

                    <HamburgerButton
                        onClick={() => setIsOpen((current) => !current)}
                    >
                        {isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    </HamburgerButton>
                </Logo>
                <FoldContainer className={classNames(isOpen && 'open')}>
                    <LinksList links={links} />
                </FoldContainer>
            </Container>
        </Wrapper>
    );
};

export default Menu;
