import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

import BowIcon from '~public/assets/icons/bow.svg';

export const Background = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
`;

const StyledBowLargeIcon = styled(BowIcon)`
    position: absolute;
    z-index: -1;
    --size: 500px;
    width: var(--size);
    height: var(--size);

    ${breakpoints.aboveTablet} {
        --size: 1900px;
    }
`;

export const LeftBowIcon = styled(StyledBowLargeIcon)`
    left: -250px;
    bottom: -210px;
    transform: rotate(120deg);

    ${breakpoints.aboveTablet} {
        left: -780px;
        bottom: -1200px;
    }
`;

export const LeftBowIconTop = styled(StyledBowLargeIcon)`
    left: -250px;
    bottom: -200px;
    transform: rotate(-75deg);

    ${breakpoints.aboveTablet} {
        left: -680px;
        bottom: -1160px;
    }
`;

export const RightBowIcon = styled(StyledBowLargeIcon)`
    right: -250px;
    bottom: -350px;
    transform: rotate(150deg);

    ${breakpoints.aboveTablet} {
        left: 25%;
        bottom: -1600px;
    }

    ${breakpoints.aboveBigDesktop} {
        left: 35%;
    }
`;
