export enum Routes {
    Homepage = '/',
    Dome = '/dome',
    DomeEstate = '/dome-estate',
    Gateway = '/gateway',
    Tokenomics = '/tokenomics',
    Team = '/team',
    Whitepaper = '/whitepaper',
    Terms = '/terms',
    PrivacyPolicy = '/privacy-policy',
    NewsList = '/news',
    News = '/news/[slug]',
    Error404 = '/404-not-found',
}
