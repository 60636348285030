import {default as NextLink} from 'next/link';
import React from 'react';

import CustomLink from '~components/atoms/CustomLink';
import {PageDataPropsType} from '~api/fetchPagesData';
import Text from '~components/atoms/Text';
import {getImageCollectionData, getImageData} from '~utils/imageData';

import {
    AddressContainer,
    CompanyName,
    Container,
    Copyright,
    EverdomeLogo,
    FooterBottom,
    FooterBottomContentWrapper,
    Link,
    LogosGrid,
} from './styles';

type FooterProps = Pick<PageDataPropsType, 'footer'>['footer']['data']['attributes'];

const Footer: React.FC<FooterProps> = ({
    logo,
    copyrightText,
    links,
    logos,
    companyName,
    address,
}) => {
    return (
        <Container>
            {logos && (
                <LogosGrid>
                    {getImageCollectionData(logos).map((data) => (
                        <img {...data} key={data.src} />
                    ))}
                </LogosGrid>
            )}

            <CustomLink href="/">
                <EverdomeLogo {...getImageData(logo)} />
            </CustomLink>
            <AddressContainer>
                <CompanyName type="content" bold>
                    {companyName}
                </CompanyName>
                <Text type="content" noMarginBottom noMarginTop>
                    {address}
                </Text>
            </AddressContainer>
            <FooterBottom>
                <FooterBottomContentWrapper>
                    <Copyright>
                        {copyrightText.replace(
                            '{{year}}',
                            String(new Date().getFullYear()),
                        )}
                    </Copyright>
                    {links &&
                        links.map(({text, url, page}) => (
                            <NextLink
                                href={url || page.data.attributes.slug || '#'}
                                passHref
                                key={text}
                            >
                                <Link>{text}</Link>
                            </NextLink>
                        ))}
                </FooterBottomContentWrapper>
            </FooterBottom>
        </Container>
    );
};

export default Footer;
