import {ImageCollectionQuery} from '~interfaces/graphql/ImageCollectionQuery';
import {ImageQuery} from '~interfaces/graphql/ImageQuery';

export type ImageDataType = {
    src: string;
    alt: string;
};

export const getImageData = (
    image: ImageQuery | undefined | null,
): ImageDataType => {
    return {
        src: image?.data?.attributes.url || '',
        alt: image?.data?.attributes?.alternativeText || '',
    };
};

export const getImageCollectionData = ({
    data,
}: ImageCollectionQuery): ImageDataType[] => {
    return data?.map(({attributes}) => ({
        src: attributes?.url || '',
        alt: attributes?.alternativeText || '',
    }));
};
