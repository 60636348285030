import {useState} from 'react';

import DiscordIcon from '~assets/icons/logo-discord.svg';
import TelegramIcon from '~assets/icons/logo-telegram.svg';
import TelegramNewsIcon from '~assets/icons/logo-telegram-news.svg';
import TwitterIcon from '~assets/icons/logo-twitter.svg';
import LinkedInIcon from '~assets/icons/logo-linkedin.svg';
import InstagramIcon from '~assets/icons/logo-instagram.svg';
import YouTubeIcon from '~assets/icons/logo-youtube.svg';
import ShareIcon from '~assets/icons/share.svg';

import {SocialsContainer} from './styles';

const data = [
    {
        url: 'https://discord.gg/everdome',
        icon: <DiscordIcon />,
    },
    {
        url: 'https://t.me/everdome',
        icon: <TelegramIcon />,
    },
    {
        url: 'https://t.me/everdome_io_news',
        icon: <TelegramNewsIcon />,
    },
    {
        url: 'https://twitter.com/Everdome_io',
        icon: <TwitterIcon />,
    },
    {
        url: 'https://www.linkedin.com/company/everdome-io',
        icon: <LinkedInIcon />,
    },
    {
        url: 'https://www.instagram.com/everdome.ig',
        icon: <InstagramIcon />,
    },
    {
        url: 'https://www.youtube.com/@EverdomeYT',
        icon: <YouTubeIcon />,
    },
];

export const Socials = () => {
    const [expand, setExpand] = useState(false);

    return (
        <SocialsContainer expand={expand}>
            <button onClick={() => setExpand(!expand)}>
                <ShareIcon />
            </button>
            {[...data].reverse().map(({url, icon}) => (
                <a href={url} target="_blank" rel="noreferrer" key={url}>
                    {icon}
                </a>
            ))}
        </SocialsContainer>
    );
};
