import classNames from 'classnames';
import React, {HTMLAttributes} from 'react';

import {StyledText} from './styles';

type TextType = 'sectionName' | 'sectionTitle' | 'largeSectionTitle' | 'content';

export type TextProps = HTMLAttributes<HTMLElement> & {
    type: TextType;
    color?: 'white' | 'gradient' | 'pink' | 'purple' | 'lightBlue' | string;
    noMarginBottom?: boolean;
    noMarginTop?: boolean;
    className?: string;
    children?: React.ReactNode;
    underline?: boolean;
    inline?: boolean;
    bold?: boolean;
    semiBold?: boolean;
    opacity?: number;
    marginLeft?: number;
    onClick?: () => void;
};

enum Tags {
    'sectionName' = 'h2',
    'sectionTitle' = 'h3',
    'largeSectionTitle' = 'h3',
    'content' = 'p',
}

enum ClassNames {
    'sectionName' = 'section-name',
    'sectionTitle' = 'section-title',
    'largeSectionTitle' = 'large-section-title',
    'content' = '',
}

const Text: React.FC<TextProps> = ({
    type,
    children,
    color = 'white',
    noMarginBottom = false,
    noMarginTop = false,
    className = '',
    underline = false,
    inline = false,
    bold = false,
    opacity = 1,
    semiBold,
    marginLeft,
    onClick = () => {},
    ...props
}) => {
    return (
        <StyledText
            as={Tags[type]}
            className={classNames(
                ClassNames[type],
                className,
                color === 'gradient' && 'gradient',
            )}
            onClick={onClick}
            color={color}
            noMarginBottom={noMarginBottom}
            noMarginTop={noMarginTop}
            underline={underline}
            inline={inline}
            bold={bold}
            opacity={opacity}
            marginLeft={marginLeft}
            semiBold={semiBold}
            {...props}
        >
            {children}
        </StyledText>
    );
};

export default Text;
