/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_COMPONENTATOMSLINK_TARGET {
    blank = 'blank',
    parent = 'parent',
    self = 'self',
    top = 'top',
}

export enum ENUM_COMPONENTATOMSNESTEDLINK_TARGET {
    blank = 'blank',
    parent = 'parent',
    self = 'self',
    top = 'top',
}

export enum ENUM_COMPONENTATOMSPRESALEINFOBLOCK_SECTIONNAMETYPE {
    icon = 'icon',
    label = 'label',
}

export enum ENUM_COMPONENTATOMSROADMAPTEXT_TYPE {
    done = 'done',
    progress = 'progress',
}

export enum ENUM_COMPONENTSECTIONTOKENPRESALE_DEFAULTLAYOUTMODE {
    presale = 'presale',
    vesting = 'vesting',
}

export enum ENUM_COMPONENTSECTIONTOKENPRESALE_TYPE {
    hero = 'hero',
    private = 'private',
    tenset = 'tenset',
}

export enum ENUM_PAGE_BACKGROUND {
    gradient = 'gradient',
    homepage = 'homepage',
    news = 'news',
    nft = 'nft',
    stars = 'stars',
    team = 'team',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
