import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

import {StyledText} from '~components/atoms/Text/styles';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: auto;

    & > p {
        margin-top: 32px;
        opacity: 0.7;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 5px;
    }
`;

export const LogosGrid = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 75px 0 134px;

    ${breakpoints.aboveTablet} {
        margin: 134px 0;
    }

    img {
        margin: 30px 40px;
    }
`;

export const EverdomeLogo = styled.img`
    width: 100%;
    max-width: 200px;
`;

export const AddressContainer = styled.div`
    text-align: center;
    white-space: pre-line;
    margin: 24px 0 32px;
`;

export const CompanyName = styled(StyledText)`
    margin: 0 0 4px;
    font-size: 16px;
`;

export const FooterBottom = styled.div`
    margin-top: 24px;
    width: 100%;
    padding: 10px 12px;
    background: rgba(13, 3, 32, 0.3);
`;

export const FooterBottomContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 1224px;
    margin: 0 auto;

    ${breakpoints.aboveTablet} {
        flex-direction: row;
    }
`;

export const Copyright = styled.p`
    margin: 0;
    font-size: 14px;
    padding: 16px 0 4px;
    text-align: center;

    ${breakpoints.aboveTablet} {
        text-align: left;
        padding: 4px 16px 4px 0;
        margin-right: auto;
    }
`;

export const Link = styled.a`
    font-size: 14px;
    text-decoration: none;
    padding: 4px 0;

    &:first-of-type {
        margin: 0 32px;
    }

    &:hover {
        text-decoration: underline;
    }
`;
