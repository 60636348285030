import React, {createContext, ReactNode, useContext} from 'react';

import {useDomeUSDPrice} from '~hooks/useDomeUSDPrice';

const DomePriceContext = createContext<{
    domePrice: number | undefined;
}>({
    domePrice: undefined,
});

export const DomePriceProvider = ({children}: {children: ReactNode}) => {
    const domePrice = useDomeUSDPrice();

    return (
        <DomePriceContext.Provider value={{domePrice}}>
            {children}
        </DomePriceContext.Provider>
    );
};

export const useDomePrice = () => useContext(DomePriceContext);
