import React, {ReactNode} from 'react';
import Link from 'next/link';

import {Container} from './styles';

type CustomLinkProps = {
    children?: ReactNode;
    href: string;
    target?: React.HTMLAttributeAnchorTarget;
    className?: string;
};
const CustomLink: React.FC<CustomLinkProps> = ({
    className,
    href,
    children,
    target = '_self',
}) => {
    return (
        <Link href={href} passHref>
            <Container className={className} target={target} rel="noreferrer">
                {children}
            </Container>
        </Link>
    );
};

export default CustomLink;
