export type ApiErrorType = {
    statusCode: number;
    error: string;
    message: string;
};

export type NestedApiErrorType = {
    message: ApiErrorType;
};

export const initialApiError: ApiErrorType = {
    statusCode: 500,
    error: 'Error',
    message: 'Unknown error',
};

export type PageList = Array<{
    id: string | number;
    slug: string;
}>;

export type ApyReturn = {
    apy: string;
};
