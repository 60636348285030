import {GetStaticPropsContext} from 'next';

import {objectHas} from '~utils/object';
import {PageList} from '~interfaces/api';

export type QueryParamsType = Record<
    string,
    string | boolean | Date | number | undefined
>;

export const createUrl = (baseUrl: string | undefined, path: string) => {
    if (/https?:/.test(path)) {
        return path;
    }
    return [
        (baseUrl || process.env.NEXT_PUBLIC_API || '').replace(/\/$/, ''),
        path.replace(/^\//, ''),
    ].join('/');
};

export const resolveUrl = (
    baseUrl: string | undefined,
    path: string,
    queryParams: QueryParamsType = {},
    bindParams: QueryParamsType = {},
) => {
    const url = new URL(createUrl(baseUrl, bindUrlParams(path, bindParams)));
    if (queryParams) {
        Object.entries(queryParams).forEach(([name, value]) => {
            if (value !== undefined) {
                url.searchParams.append(name, value.toString());
            }
        });
    }
    return url.href;
};

export const bindUrlParams = (route: string, params: QueryParamsType) => {
    return route.replace(/:(\w+)/g, (_, key) =>
        objectHas(params, key) ? params[key]?.toString() || '' : '',
    );
};

const PREVIEW_PREFIX = 'preview_';
export const slugToPage = (
    ctx: GetStaticPropsContext,
    pages: PageList,
    defaultSlug?: string,
) => {
    let slug = ctx.params?.slug || defaultSlug;
    if (!slug) {
        return undefined;
    }
    slug = `${slug}`;

    if (slug.startsWith(PREVIEW_PREFIX)) {
        const [, id] = slug.split('_');
        return {
            slug: '',
            id: Number(id),
        };
    }
    return pages.find((p) => p.slug === slug);
};
