import React, {useMemo} from 'react';
import Head from 'next/head';
import {useTranslation} from 'next-i18next';

import {PageDataPropsType} from '~api/fetchPagesData';
import {If} from '~atoms/If';
import {getImageData} from '~utils/imageData';
import defaultPoster from '~assets/poster/poster.png';

type SeoData = Pick<PageDataPropsType, 'seo'>['seo'];

type HTMLHeadProps = {seo: SeoData};

export const HTMLHead: React.FC<HTMLHeadProps> = ({seo}) => {
    const {t} = useTranslation('common');

    const {pageTitle, pageDescription, pagePoster} = useMemo(() => {
        const pick = <T extends keyof SeoData>(
            key: T,
            defaultValue: SeoData[T] | undefined = undefined,
        ) => (seo && seo[key] ? seo[key] : defaultValue);

        return {
            pageTitle: pick('title', t('appName') as string),
            pageDescription: pick('description', t('appName') as string),
            pagePoster:
                getImageData(pick('poster'))?.src ||
                `${(process.env.NEXT_PUBLIC_API || '').replace('/api', '')}${
                    defaultPoster.src
                }`,
        };
    }, [seo]);

    return (
        <Head>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>

            <meta name="referrer" content="origin" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <If condition={pageDescription}>
                <meta name="description" content={pageDescription} />
                <meta property="og:description" content={pageDescription} />
            </If>

            <meta property="og:title" content={pageTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={pagePoster} />

            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="black-translucent"
            />
            <meta name="theme-color" content="#000000" />
            <If condition={process.env.NEXT_PUBLIC_GTM_ID}>
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
                    `,
                    }}
                />
            </If>
        </Head>
    );
};
