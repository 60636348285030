import {LeftBowIcon, LeftBowIconTop, RightBowIcon} from '../Homepage/styles';

import {Background} from './styles';

export const NftBackground = () => {
    return (
        <Background>
            <LeftBowIcon />
            <LeftBowIconTop />
            <RightBowIcon />
        </Background>
    );
};
