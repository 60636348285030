import useFetch from '~api/useFetch';
import {ApiRoutes} from '~interfaces/ApiRoutes';

export default function useFetchDomePrice() {
    const {apiGet} = useFetch();

    return () =>
        apiGet(ApiRoutes.DOME_USD_PRICE, {
            baseUrl: process.env.NEXT_PUBLIC_ED_API,
        });
}
