import {
    Background,
    LeftBowIcon,
    LeftBowIconTop,
    RightBowIcon,
    WaveIconTop,
} from './styles';

export const NewsBackground = () => {
    return (
        <Background>
            <WaveIconTop />
            <LeftBowIcon />
            <LeftBowIconTop />
            <RightBowIcon />
        </Background>
    );
};
