import {ENUM_PAGE_BACKGROUND} from 'interfaces/graphql/globalTypes';

import {HomepageBackground} from './Homepage';
import {TeamBackground} from './Team';
import {StartBackground} from './Stars';
import {NewsBackground} from './News';
import {NftBackground} from './NftBackground';

type BackgroundProps = {
    type: ENUM_PAGE_BACKGROUND;
};

export const Background = ({type}: BackgroundProps) => {
    switch (type) {
        case ENUM_PAGE_BACKGROUND.homepage:
            return <HomepageBackground />;
        case ENUM_PAGE_BACKGROUND.team:
            return <TeamBackground />;
        case ENUM_PAGE_BACKGROUND.stars:
            return <StartBackground />;
        case ENUM_PAGE_BACKGROUND.news:
            return <NewsBackground />;
        case ENUM_PAGE_BACKGROUND.nft:
            return <NftBackground />;
        default:
            return null;
    }
};
