import styled, {css} from 'styled-components';

import {TextProps} from '.';

export const StyledText = styled.div<TextProps>`
    ${({semiBold}) =>
        semiBold &&
        css`
            font-weight: 400;
        `}

    ${({bold}) =>
        bold &&
        css`
            font-weight: bold;
        `}

    ${({inline}) =>
        inline &&
        css`
            display: inline;
        `}

    ${({noMarginTop}) =>
        noMarginTop &&
        css`
            margin-top: 0;
        `}

    ${({noMarginBottom}) =>
        noMarginBottom &&
        css`
            margin-bottom: 0;
        `}

    ${({marginLeft}) =>
        marginLeft &&
        css`
            margin-left: ${marginLeft}px;
        `}

    ${({color}) => {
        switch (color) {
            case 'pink': {
                return css`
                    color: var(--pink);

                    &::after {
                        background-color: var(--pink);
                    }
                `;
            }
            case 'white': {
                return css`
                    .white {
                        color: var(--font-color);

                        &::after {
                            background-color: var(--font-color);
                        }
                    }
                `;
            }
            case 'purple': {
                return css`
                    color: var(--light-purple);

                    &::after {
                        background-color: var(--font-color);
                    }
                `;
            }
            case 'lightBlue': {
                return css`
                    color: var(--light-blue);
                `;
            }
            default:
                return css`
                    color: ${color};
                `;
        }
    }};

    ${({underline}) =>
        underline &&
        css`
            position: relative;
            &::after {
                content: '';
                color: inherit;
                height: 1px;
                width: 120%;

                margin-top: 2px;
            }
        `}

    opacity: ${({opacity}) => opacity};
`;
