import {toast} from 'react-toastify';

export default function getErrorMessage(message: string | any) {
    if (message.includes('"message":')) {
        let messageJson = JSON.parse(message);
        if (messageJson?.message) {
            return messageJson.message;
        }
    }

    switch (message) {
        case 'execution reverted: no-tokens-left-to-buy':
            return 'You bought max amount of tokens';
        case 'execution reverted: too-little-tokens-holded':
            return "It appears you've sold HERO during the holding period, which has removed your address from the whitelist.";
        case 'execution reverted: no-funds-end':
            return 'You cannot send transaction without BNB';
        case 'execution reverted: Vesting started':
            return 'Presale finished, because vesting has already started';
        case 'execution reverted: ico-ended':
            return 'Presale finished, because vesting has already started';
        case 'execution reverted: BEP20: transfer amount exceeds allowance':
            return 'You cannot stake without approval';
        case 'execution reverted: already-minted':
            return 'Already claimed';
        case 'execution reverted: too-late':
            return 'The time is over';
        case 'execution reverted: proof-incorrect':
            return 'Provided data is incorrect';
        case 'execution reverted: too-early':
            return 'Not started';
        default:
            return `Something went wrong: "${message}"`;
    }
}

export function showSuccessMessage(message: string) {
    toast.success(message, {
        position: 'top-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function showErrorMessage(message: string, id?: string) {
    toast.error(message, {
        position: 'top-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...(id && {toastId: id}),
    });
}
