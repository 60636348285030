import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const Background = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
`;

export const Top = styled.div`
    background: radial-gradient(
        68.83% 68.83% at 34.39% 0%,
        rgba(255, 0, 153, 0.9) 0%,
        rgba(164, 132, 255, 0.5) 55.5%,
        rgba(72, 5, 139, 0) 100%
    );
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
`;

export const Stars = styled.div`
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    div {
        height: 300px;
        width: 100vw;
        position: absolute;
        top: 0;
        overflow: hidden;

        ${breakpoints.aboveTablet} {
            height: 500px;
        }
    }

    canvas {
        position: relative !important;
        z-index: 2;
    }
`;
