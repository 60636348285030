import React from 'react';

import MemoizedLink from '~components/atoms/MemoizedLink';
import {GlobalComponentsQueryMenuDataAttributesLinks} from '~interfaces/graphql/GlobalComponentsQuery';

type LinksListProps = {
    links: GlobalComponentsQueryMenuDataAttributesLinks[];
};

const LinksList = ({links}: LinksListProps) => {
    return (
        <>
            {links.map((link) => (
                <MemoizedLink key={link.text} link={link} />
            ))}
        </>
    );
};
export default LinksList;
