import React, {useMemo} from 'react';
import LinkRoot from 'next/link';

import {
    GlobalComponentsQueryMenuDataAttributesLinks,
    GlobalComponentsQueryMenuDataAttributesLinksChildrenLinks,
} from '~interfaces/graphql/GlobalComponentsQuery';
import {FCC} from '~utils/utils';
import {linkProps} from '~utils/link';

import {StyledPageLink} from './styles';

export type PageLinkProps = {
    link: Partial<
        | GlobalComponentsQueryMenuDataAttributesLinks
        | GlobalComponentsQueryMenuDataAttributesLinksChildrenLinks
    >;
};

const PageLink: FCC<PageLinkProps> = ({link}) => {
    const {href, ...params} = useMemo(() => linkProps(link), [link]);

    return (
        <StyledPageLink>
            <LinkRoot href={href} passHref {...params}>
                <a {...params}>{link.text}</a>
            </LinkRoot>
        </StyledPageLink>
    );
};

export default PageLink;
