import React from 'react';

import {LinkQueryChildrenLinks} from '~interfaces/graphql/LinkQuery';
import PageLink from '~components/atoms/PageLink';

import {Dropdown as DropdownContainer, StyledLink, Submenu} from './styles';

const LinkMemo = React.memo(PageLink);

const DropdownList = ({children}: {children: LinkQueryChildrenLinks[]}) => {
    return (
        <>
            {children.map((link) => (
                <LinkMemo link={link} key={link.text} />
            ))}
        </>
    );
};

type DropdownProps = {
    children: LinkQueryChildrenLinks[];
    text: string;
};

const Dropdown = ({children, text: parentText}: DropdownProps) => {
    return (
        <DropdownContainer>
            <StyledLink>{parentText}</StyledLink>
            <Submenu>
                <DropdownList children={children} />
            </Submenu>
        </DropdownContainer>
    );
};

export default Dropdown;
