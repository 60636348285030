import {AnchorHTMLAttributes} from 'react';
import {linkRel, linkTarget} from '@uigstudio/uig-nextjs-helper';

import {LinkQuery} from '~interfaces/graphql/LinkQuery';
import {NestedLinkQuery} from '~interfaces/graphql/NestedLinkQuery';

export type EDLink = Partial<LinkQuery | NestedLinkQuery>;

export function slugToUrl(slug: string, prefix?: string) {
    const url = [prefix, 'homepage' === slug ? '' : slug]
        .filter((e) => !!e)
        .join('/');
    return `/${url}`;
}

export type PickVariant<O, R extends keyof O, P extends keyof O> = Required<
    Pick<O, R>
> &
    Partial<Pick<O, P>>;

type LinkProps = PickVariant<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'target',
    'rel'
>;

export function linkUrl(link?: EDLink | null): string {
    if (link?.page?.data?.attributes?.slug) {
        return slugToUrl(link.page.data.attributes.slug);
    }
    if (link?.url) {
        return link.url;
    }
    return '#';
}

export function linkProps(link?: EDLink | null) {
    const target = linkTarget(link?.target || '');
    let props: LinkProps = {
        href: linkUrl(link),
        target,
    };
    if (target === '_blank') {
        props = {...props, ...linkRel(target)};
    }
    return props;
}
