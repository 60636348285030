import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

import BowIcon from '~public/assets/icons/bow.svg';
import BowFilledIcon from '~public/assets/icons/bow-fill.svg';

export const Background = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
`;

const StyledBowLargeIcon = styled(BowIcon)`
    position: absolute;
    z-index: -1;
    --size: 500px;
    width: var(--size);
    height: var(--size);

    ${breakpoints.aboveTablet} {
        --size: 1900px;
    }
`;

export const BottomLeftBowIcon = styled(StyledBowLargeIcon)`
    left: -250px;
    bottom: -410px;
    transform: rotate(120deg);
    z-index: 1;

    ${breakpoints.aboveTablet} {
        left: -780px;
        bottom: -1650px;
    }
`;

export const BottomLeftBowIconTop = styled(StyledBowLargeIcon)`
    left: -250px;
    bottom: -400px;
    transform: rotate(-75deg);
    z-index: 2;

    ${breakpoints.aboveTablet} {
        left: -660px;
        bottom: -1620px;
    }
`;

export const TopLeftBow = styled(BowFilledIcon)`
    position: absolute;
    top: -290px;
    left: -440px;
`;

export const BottomRightBow = styled(BowFilledIcon)`
    position: absolute;
    bottom: -260px;
    right: -460px;
`;
