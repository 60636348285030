import React, {ReactNode, useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {ToastContainer} from 'react-toastify';

import Footer from '~components/molecules/Footer';
import Menu from '~components/molecules/Menu';
import {PageDataPropsType} from '~api/fetchPagesData';
import {Socials} from '~components/molecules/Socials';
import {StakingModalContentProvider} from '~providers/StakingModalContentProvider';
import {Portals} from '~constants/portals';
import {DomePriceProvider} from '~providers/DomePriceProvider';

import {Background} from './Background';
import {HTMLHead} from './HTMLHead';
import {Container, Content} from './styles';

type LayoutProps = Pick<
    PageDataPropsType,
    'background' | 'menu' | 'footer' | 'seo'
> & {
    children?: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({
    background,
    menu,
    footer,
    seo,
    children,
}) => {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_GTM_ID) {
            TagManager.initialize({
                gtmId: process.env.NEXT_PUBLIC_GTM_ID,
                dataLayer: [
                    ['js', new Date()],
                    ['config', process.env.NEXT_PUBLIC_GTM_ID],
                ],
            });
        }
    }, []);

    return (
        <>
            <HTMLHead seo={seo} />
            <Container>
                <DomePriceProvider>
                    <StakingModalContentProvider>
                        {menu && <Menu {...menu.data.attributes} />}
                        <Socials />
                        <div id={Portals.STAKE_MODAL} />
                        <div id={Portals.SWITCH_NETWORK} />
                        <div id={Portals.CONNECT_WALLET} />
                        <Content>{children && children}</Content>
                        {footer && <Footer {...footer.data.attributes} />}
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                        <Background type={background} />
                    </StakingModalContentProvider>
                </DomePriceProvider>
            </Container>
        </>
    );
};

export default Layout;
