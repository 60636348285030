import {parseApiError} from '~utils/parseApiError';
import safeJsonParse from '~utils/safeJsonParser';
import {QueryParamsType, resolveUrl} from '~utils/url';

// eslint-disable-next-line no-undef
export type FetchRequestInit = RequestInit & {
    queryParams?: QueryParamsType;
    bindParams?: QueryParamsType;
    data?: Record<string, unknown>;
    baseUrl?: string;
};

export type FetchRequestError = {
    title: string;
    detail: string;
    status: number;
};

export default function useFetch() {
    const fetchAction =
        (type: string) =>
        <T = any>(
            url: string,
            {
                headers,
                method,
                queryParams,
                bindParams,
                data,
                body,
                baseUrl,
                ...params
            }: FetchRequestInit = {},
        ) => {
            const parsedBody = data ? JSON.stringify(data) : body ? body : undefined;

            return fetch(resolveUrl(baseUrl, url, queryParams, bindParams), {
                ...params,
                ...(parsedBody ? {body: parsedBody} : {}),
                method: type,
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
            }).then((response) => {
                return new Promise<T>((resolve, reject) => {
                    response.text().then((data) => {
                        try {
                            const responseData = safeJsonParse(data, true);
                            if (response.ok) {
                                resolve(responseData);
                            } else {
                                reject(parseApiError(responseData));
                            }
                        } catch (e) {
                            reject(parseApiError(e));
                        }
                    });
                });
            });
        };

    return {
        apiGet: fetchAction('GET'),
        apiPost: fetchAction('POST'),
        apiPut: fetchAction('PUT'),
        apiPatch: fetchAction('PATCH'),
        apiDelete: fetchAction('DELETE'),
        fakeGet: <T>(url: string, data: T) => {
            // eslint-disable-next-line no-console
            console.log('Fake fetch data:', url);
            return new Promise<T>((resolve) =>
                setTimeout(() => resolve(data), 1000),
            );
        },
    };
}
