import {useEffect, useState} from 'react';
import {useBlockNumber} from '@usedapp/core';

import useFetchDomePrice from '~api/useFetchDomePrice';
import {showErrorMessage} from '~utils/getErrorMessage';
import {ApiErrorType} from '~interfaces/api';

export const useDomeUSDPrice = () => {
    const [price, setPrice] = useState<number | undefined>(undefined);
    const blockNo = useBlockNumber();

    const fetchDomePrice = useFetchDomePrice();
    useEffect(() => {
        async function getPrice() {
            try {
                const response = await fetchDomePrice();
                setPrice(parseFloat(response.usd));
            } catch (err) {
                showErrorMessage((err as ApiErrorType).message);
            }
        }

        getPrice();
    }, [fetchDomePrice, blockNo]);

    return price;
};
