import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export type StyledLinkProps = {};

export const StyledPageLink = styled.div`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    font-weight: 300;
    font-size: 22px;
    white-space: nowrap;

    ${breakpoints.aboveTablet} {
        font-size: 16px;
        font-weight: 400;
    }
    a {
        text-decoration: none;
    }
    ${breakpoints.tablet} {
        margin: 28px 0px;
    }
`;
