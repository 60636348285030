import {
    Background,
    BottomLeftBowIcon,
    BottomLeftBowIconTop,
    BottomRightBow,
    TopLeftBow,
} from './styles';

export const TeamBackground = () => {
    return (
        <Background>
            <TopLeftBow />
            <BottomLeftBowIcon />
            <BottomLeftBowIconTop />
            <BottomRightBow />
            <BottomRightBow />
        </Background>
    );
};
