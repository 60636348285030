import styled from 'styled-components';

export const Background = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
`;
